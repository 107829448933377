import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Container } from './ui';
import Logo from './Logo';
import Navigation from './Navigation';
import { minBreakpointQuery, standardColours, brandColours } from '../styles';

const StyledHeader = styled.header`
  ${({ altTheme }) => {
    if (altTheme) {
      return css`
        color: ${standardColours.white};
        background-color: ${brandColours.slate[600]};
      `;
    }
  }}
`;

const StyledInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;

  ${minBreakpointQuery.large`
    padding-top: 30px;
    padding-bottom: 30px;
  `}

  ${minBreakpointQuery.xxlarge`
    padding-top: 40px;
    padding-bottom: 40px;
  `}
`;

const Header = ({ scroll, setScroll, altTheme }) => {
  const [displayNav, setDisplayNav] = useState(false);

  return (
    <StyledHeader altTheme={altTheme}>
      <Container>
        <StyledInner>
          <Logo displayNav={displayNav} alt={altTheme} />
          <Navigation
            displayNav={displayNav}
            setDisplayNav={setDisplayNav}
            scroll={scroll}
            setScroll={setScroll}
            altTheme={altTheme}
          />
        </StyledInner>
      </Container>
    </StyledHeader>
  );
};

export default Header;
