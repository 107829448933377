import React from 'react';
import styled from 'styled-components';
import { Svg } from './Svg';
import arrowIcon from '../../images/arrow.svg';

const StyledArrowIcon = styled(Svg)`
  height: 14px;
  width: 18px;
`;

export const ArrowIcon = ({ ...props }) => (
  <StyledArrowIcon image={arrowIcon} {...props} />
);
