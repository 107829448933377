import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
  fluidFontSize,
  zIndexLayers,
  standardTransition,
} from '../styles';
import { Link, Button } from './ui';

const StyledNavigation = styled.nav``;

const StyledToggle = styled.button`
  ${maxBreakpointQuery.mlarge`
    position: relative;
    margin: 0;
    padding: 0;
    height: 100%;
    background: none;
    border: none;
    cursor: pointer;

    ${({ displayNav }) => {
      if (displayNav) {
        return css`
          z-index: ${zIndexLayers.fifth};
          background-color: ${brandColours.slate[500]};
        `;
      }
    }};
  `}

  ${minBreakpointQuery.mlarge`
    display: none;
  `}

  span {
    ${maxBreakpointQuery.mlarge`
      display: block;
      height: 3px;
      width: 35px;
      margin: 6px 5px;
      background-color: ${({ displayNav, altTheme }) =>
        displayNav || altTheme
          ? standardColours.white
          : brandColours.slate[500]};
      transition: ${standardTransition('background-color')};
    `}

    ${maxBreakpointQuery.medium`
      width: 30px;
    `}

    ${maxBreakpointQuery.small`
      margin-top: 4px;
      margin-bottom: 4px;
      height: 2px;
      width: 25px;
    `}

    ${maxBreakpointQuery.tiny`
      width: 20px;
    `}
  }
`;

const StyledList = styled.ul`

  ${maxBreakpointQuery.mlarge`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${zIndexLayers.fourth};
    display: ${({ displayNav }) => (displayNav ? 'block' : 'none')};
    overflow: auto;
    padding: 110px 20px 20px;
    background-color: ${brandColours.slate[500]};
  `}

  ${maxBreakpointQuery.medium`
    padding-top: 100px;
  `}

  ${maxBreakpointQuery.small`
    padding-top: 90px;
  `}

  ${maxBreakpointQuery.tiny`
    padding-top: 80px;
  `}
`;

const StyledItem = styled.li`
  ${maxBreakpointQuery.mlarge`
    margin-top: 5px;
  `}

  ${minBreakpointQuery.mlarge`
    display: inline-block;
    margin-left: 15px;
  `}

  ${minBreakpointQuery.large`
    margin-left: 25px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-left: 35px;
  `}

  &:first-child {
    ${maxBreakpointQuery.mlarge`
      margin-top: 0;
    `}

    ${minBreakpointQuery.mlarge`
      margin-left: 0;
    `}
  }
`;

const StyledLink = styled(Link)`
  position: relative;
  display: block;
  ${fluidFontSize(
    '18px',
    '30px',
    breakpointSizes.tiny,
    breakpointSizes.mlarge
  )};
  line-height: 1.5;

  ${maxBreakpointQuery.mlarge`
    padding: 10px 0;
    color: ${standardColours.white};
  `}

  ${minBreakpointQuery.mlarge`
    ${fontSize(18)};
  `}

  &:after {
    ${minBreakpointQuery.mlarge`
      content: '';
      position: absolute;
      bottom: 4px;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: ${({ altTheme }) =>
        altTheme ? standardColours.white : brandColours.slate[500]};
      opacity: 0;
      transition: ${standardTransition('opacity')};
    `}
  }

  &:hover,
  &.current-page {
    &:after {
      opacity: 1;
    }
  }
`;

const StyledButton = styled(Button)`

  ${maxBreakpointQuery.mlarge`
    margin-top: 20px;
    background-color: ${standardColours.white};
    color: ${brandColours.slate[500]};
  `}

  ${minBreakpointQuery.mlarge`
    margin-left: 10px;
  `}

  ${minBreakpointQuery.large`
    margin-left: 15px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-left: 20px;
  `}

  svg {

    ${maxBreakpointQuery.mlarge`
      fill: ${brandColours.slate[500]};
    `}
  }
`;

const Navigation = ({
  displayNav,
  setDisplayNav,
  scroll,
  setScroll,
  altTheme,
}) => {
  const {
    datoCmsHeader: { navigation },
  } = useStaticQuery(graphql`
    query NavigationQuery {
      datoCmsHeader {
        navigation {
          id
          text
          page {
            ...LinkFragment
          }
        }
      }
    }
  `);

  const handleClick = () => {
    setScroll(!scroll);
    setDisplayNav(!displayNav);
  };

  return (
    <StyledNavigation>
      <StyledToggle
        aria-label="Open / Close Navigation"
        displayNav={displayNav}
        altTheme={altTheme}
        onClick={() => handleClick()}
      >
        <span />
        <span />
        <span />
      </StyledToggle>
      <StyledList displayNav={displayNav}>
        {navigation.map(({ id, text, page }) => (
          <StyledItem key={id}>
            <StyledLink
              to={page.slug}
              linkData={page}
              activeClassName="current-page"
              partiallyActive={true}
              altTheme={altTheme}
            >
              {text}
            </StyledLink>
          </StyledItem>
        ))}
        <StyledItem>
          <StyledButton to="careers">See open positions</StyledButton>
        </StyledItem>
      </StyledList>
    </StyledNavigation>
  );
};

export default Navigation;
