import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import styled, { css } from 'styled-components';
import {
  standardColours,
  brandColours,
  brandFonts,
  fontSize,
  standardTransition,
} from '../../styles';
import { Link } from './Link';
import { ArrowIcon } from './Icon';

// moved up for hover
const StyledIcon = styled(ArrowIcon)`
  margin-top: 4px;
  margin-left: 10px;
  fill: ${({ alt }) => (alt ? brandColours.slate[500] : standardColours.white)};
  transition: ${standardTransition('transform')};

  ${({ arrowDown }) => {
    if (arrowDown) {
      return css`
        transform: rotate(90deg);
      `;
    }
  }};
`;

const commonButtonStyles = () => {
  return css`
    display: inline-flex;
    align-items: center;
    padding: 12px 24px;
    color: ${({ alt }) =>
      alt ? brandColours.slate[500] : standardColours.white};
    ${brandFonts.futuraPTDemi()};
    ${fontSize(18)};
    line-height: 1.5;
    text-align: center;
    background-color: ${({ alt }) =>
      alt ? standardColours.white : brandColours.slate[500]};

    &:hover {
      ${StyledIcon} {
        transform: ${({ arrowDown }) =>
          arrowDown ? 'translateY(3px) rotate(90deg)' : 'translateX(3px)'};
      }
    }
  `;
};

const StyledButton = styled.button`
  ${commonButtonStyles()};
  ${brandFonts.futuraPTBook};
  border: none;
  cursor: pointer;
`;

const StyledButtonLink = styled(Link)`
  ${commonButtonStyles()};
`;

const StyledButtonLinkExternal = styled(OutboundLink)`
  ${commonButtonStyles()};
`;

export const Button = ({ children, alt, arrowDown, ...props }) => {
  const ButtonComponent = props.to
    ? StyledButtonLink
    : props.href
    ? StyledButtonLinkExternal
    : StyledButton;

  return (
    <ButtonComponent alt={alt} arrowDown={arrowDown} {...props}>
      {children}
      <StyledIcon alt={alt} arrowDown={arrowDown} />
    </ButtonComponent>
  );
};
