import React from 'react';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
} from '../../styles';

const StyledSlider = styled(SlickSlider)`
  .slick-arrow {
    position: absolute;
    bottom: 20px;
    padding: 0;
    height: 22px;
    width: 22px;
    ${fontSize(0)};
    background: none;
    border: none;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      height: 16px;
      width: 16px;
      border: 5px solid ${brandColours.slate[500]};
      border-bottom: 0;
      border-left: 0;
      border-radius: 2px;
    }

    &.slick-prev {
      left: 40%;

      ${minBreakpointQuery.tsmall`
        left: 42%;
      `}

      ${minBreakpointQuery.smedium`
        left: 44%;
      `}

      ${minBreakpointQuery.mlarge`
        left: 46%;
      `}

      &:before {
        transform: rotate(-135deg);
      }
    }

    &.slick-next {
      right: 40%;

      ${minBreakpointQuery.tsmall`
        right: 42%;
      `}

      ${minBreakpointQuery.smedium`
        right: 44%;
      `}

      ${minBreakpointQuery.mlarge`
        right: 46%;
      `}

      &:before {
        transform: rotate(45deg);
      }
    }
  }

  .slick-dots {
    position: absolute;
    top: 10px;
    right: 10px;
    left: 10px;
    z-index: 1;

    li {
      display: inline-block;
      vertical-align: top;
      padding: 0 2.5px;
      height: 5px;
      width: ${({ numSlides }) =>
        numSlides ? `calc(100% / ${numSlides})` : 'auto'};

      button {
        position: relative;
        display: block;
        overflow: hidden;
        height: 5px;
        width: 100%;
        text-indent: -9999em;
        background: none;
        border: none;
        cursor: pointer;

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }

        &:before {
          background: ${standardColours.white};
          opacity: 0.4;
        }

        &:after {
          background: ${standardColours.white};
          transform: translateX(-101%);
        }
      }

      &.slick-active {
        button {
          &:after {
            animation: load
              ${({ autoplaySpeed }) =>
                autoplaySpeed ? autoplaySpeed / 1000 : '3'}s
              linear forwards;

            @keyframes load {
              0% {
                transform: translateX(-101%);
              }

              100% {
                transform: translateX(0);
              }
            }
          }
        }
      }
    }
  }

  &:hover {
    .slick-dots {
      li {
        &.slick-active {
          button {
            &:after {
              animation-play-state: paused;
            }
          }
        }
      }
    }
  }
`;

export const Slider = ({ numSlides, sliderOptions, children, ...props }) => (
  <StyledSlider numSlides={numSlides} {...sliderOptions} {...props}>
    {children}
  </StyledSlider>
);
