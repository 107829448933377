import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import GlobalStyle from '../styles/GlobalStyle';
import Header from './Header';
import Footer from './Footer';
import CookieNotice from './CookieNotice';

const Layout = ({ children, altTheme, altThemeHeader }) => {
  const {
    datoCmsSite: { faviconMetaTags },
  } = useStaticQuery(graphql`
    query LayoutQuery {
      datoCmsSite {
        faviconMetaTags {
          ...GatsbyDatoCmsFaviconMetaTags
        }
      }
    }
  `);

  const [scroll, setScroll] = useState(true);

  return (
    <>
      <HelmetDatoCms favicon={faviconMetaTags}>
        <html lang="en-GB" />
      </HelmetDatoCms>
      <GlobalStyle scroll={scroll} altTheme={altTheme} />
      <Header
        scroll={scroll}
        setScroll={setScroll}
        altTheme={altTheme || altThemeHeader}
      />
      {children}
      <Footer />
      <CookieNotice />
    </>
  );
};

export default Layout;
