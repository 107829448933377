import { css } from 'styled-components';

export const brandFonts = {
  futuraPTBook: () => {
    return css`
      font-family: 'futura-pt', sans-serif;
      font-weight: 400;
      font-style: normal;
    `;
  },
  futuraPTBookOblique: () => {
    return css`
      font-family: 'futura-pt', sans-serif;
      font-weight: 400;
      font-style: italic;
    `;
  },
  futuraPTDemi: () => {
    return css`
      font-family: 'futura-pt', sans-serif;
      font-weight: 600;
      font-style: normal;
    `;
  },
  futuraPTDemiOblique: () => {
    return css`
      font-family: 'futura-pt', sans-serif;
      font-weight: 600;
      font-style: italic;
    `;
  },
  futuraPTBold: () => {
    return css`
      font-family: 'futura-pt-bold', sans-serif;
      font-weight: 700;
      font-style: normal;
    `;
  },
  futuraPTBoldOblique: () => {
    return css`
      font-family: 'futura-pt-bold', sans-serif;
      font-weight: 700;
      font-style: italic;
    `;
  },
  quartoBlack: () => {
    return css`
      font-family: 'QuartoBlack';
      font-weight: normal;
    `;
  },
};
