export const buildUrl = (slug, linkData) => {
  let url = '/';

  if (linkData) {
    if (linkData.treeParent) {
      if (
        linkData.treeParent.treeParent &&
        linkData.treeParent.treeParent.treeParent
      ) {
        url += `${linkData.treeParent.treeParent.treeParent.slug}/`;
      }

      if (linkData.treeParent.treeParent) {
        url += `${linkData.treeParent.treeParent.slug}/`;
      }

      url += `${linkData.treeParent.slug}/`;
    } else if (linkData.internal && linkData.internal.type) {
      if (linkData.internal.type === 'DatoCmsDepartment') {
        url += 'departments/';
      }
    }
  }

  url += `${slug.replace(/^\/|\/$/g, '')}/`;

  return url;
};
