import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import styled from 'styled-components';
import {
  brandColours,
  standardColours,
  fontSize,
  minBreakpointQuery,
  maxBreakpointQuery,
} from '../styles';
import { Container, Svg, Link } from './ui';
import Logo from './Logo';
import instagramIcon from '../images/instagram.svg';
import linkedinIcon from '../images/linkedin.svg';
import fastTrackLogo from '../images/fast-track.png';

const StyledFooter = styled.footer`
  background-color: ${brandColours.slate[500]};
  color: ${standardColours.white};
`;

const StyledInner = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 50px;
  padding: 55px 0;

  ${minBreakpointQuery.medium`
    padding: 70px 0;
  `};

  ${minBreakpointQuery.mlarge`
    grid-template-columns: 1fr 0.5fr 0.7fr 490px;
    padding: 90px 0;
  `};

  ${minBreakpointQuery.large`
    padding: 110px 0;
  `};
`;

const StyledTop = styled.div``;

const StyledCopyright = styled.p`
  ${fontSize(12)};
  line-height: 2.3;
  margin-top: 20px;
  margin-bottom: 30px;
`;

const StyledSocials = styled.div``;

const StyledSocialLink = styled(OutboundLink)`
  margin-left: 10px;

  &:first-child {
    margin-left: 0;
  }
`;

const StyledIcon = styled(Svg)`
  height: 22px;
  width: 22px;
`;

const StyledLinks = styled.ul`
  ${maxBreakpointQuery.smedium`
    grid-column-start: 2;
    grid-row-start: 1;
    grid-row-end: span 2;
  `};
`;

const StyledLink = styled.li`
  line-height: 1.7;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const StyledBrands = styled.div`
  margin-top: 40px;

  ${minBreakpointQuery.mlarge`
    margin-top:8px;
  `};
`;

const StyledBrandLink = styled(OutboundLink)`
  display: block;
  margin-top: 25px;

  &:first-child {
    margin-top: 0;
  }
`;

const StyledBrandLogo = styled.img`
  height: 15px;
`;

const StyledLogos = styled.div`
  display: grid;
  grid-template-columns: 170px 1fr;
  column-gap: 10px;
  row-gap: 10px;
  margin-top: 35px;

  ${maxBreakpointQuery.smedium`
    grid-column-end: span 2;
  `};

  ${minBreakpointQuery.mlarge`
    margin-top: 0;
    grid-template-columns: 120px 1fr 1fr;
  `};
`;

const StyledImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${brandColours.slate[600]};
  border-radius: 7px;
  grid-column-start: 1;

  ${minBreakpointQuery.mlarge`
    grid-column-start: 2;

    &:nth-child(even){
      grid-column-start: 3;
    }
  `};
`;

const StyledImage = styled.img`
  max-height: 90px;
  max-width: 100%;
  grid-column-start: 1;
  padding: 15px 10px;
`;

const StyledFastTrack = styled.img`
  max-height: 190px;
  grid-column-start: 2;
  grid-row-start: 1;
  grid-row-end: span 3;

  ${minBreakpointQuery.mlarge`
    grid-column-start: 1;
  `};
`;

const Footer = () => {
  const {
    datoCmsSite: {
      globalSeo: { siteName },
    },
    datoCmsFooter: { links, logos },
    datoCmsContactInformation: { instagramProfile, linkedinProfile },
    allDatoCmsBrand: { brands },
  } = useStaticQuery(graphql`
    query FooterQuery {
      datoCmsSite {
        globalSeo {
          siteName
        }
      }
      datoCmsFooter {
        links {
          id
          text
          page {
            ...LinkFragment
          }
        }
        logos {
          url
          alt
        }
      }
      datoCmsContactInformation {
        instagramProfile
        linkedinProfile
      }
      allDatoCmsBrand(sort: { fields: position }) {
        brands: nodes {
          id
          websiteUrl
          lightLogo {
            url
            alt
          }
        }
      }
    }
  `);

  return (
    <StyledFooter>
      <Container>
        <StyledInner>
          <StyledTop>
            <Logo alt={true} />
            <StyledCopyright>
              &copy; {siteName} {new Date().getFullYear()}
            </StyledCopyright>
            {(instagramProfile || linkedinProfile) && (
              <StyledSocials>
                {instagramProfile && (
                  <StyledSocialLink
                    href={instagramProfile}
                    target={`_blank`}
                    rel={`noopener noreferrer`}
                  >
                    <StyledIcon image={instagramIcon} title={`Instagram`} />
                  </StyledSocialLink>
                )}
                {linkedinProfile && (
                  <StyledSocialLink
                    href={linkedinProfile}
                    target={`_blank`}
                    rel={`noopener noreferrer`}
                  >
                    <StyledIcon image={linkedinIcon} title={`Linkedin`} />
                  </StyledSocialLink>
                )}
              </StyledSocials>
            )}
          </StyledTop>
          <StyledLinks>
            {links.map(({ id, text, page }) => {
              return (
                <StyledLink key={id}>
                  <Link to={page.slug} linkData={page} title={text}>
                    {text}
                  </Link>
                </StyledLink>
              );
            })}
          </StyledLinks>
          {brands.length > 0 && (
            <StyledBrands>
              {brands.map(({ id, websiteUrl, lightLogo: { url, alt } }) => (
                <StyledBrandLink
                  key={id}
                  href={websiteUrl}
                  target="_blank"
                  rel="noopener"
                >
                  <StyledBrandLogo src={url} alt={alt} loading="lazy" />
                </StyledBrandLink>
              ))}
            </StyledBrands>
          )}
          {logos && (
            <StyledLogos>
              {logos.map(({ url, alt }, i) => (
                <StyledImageContainer>
                  <StyledImage
                    key={`logo-${i}`}
                    src={url}
                    alt={alt}
                    loading="lazy"
                  />
                </StyledImageContainer>
              ))}
              <StyledFastTrack
                src={fastTrackLogo}
                alt={'Fast Track 100'}
                loading="lazy"
              />
            </StyledLogos>
          )}
        </StyledInner>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
