import React from 'react';
import styled, { css } from 'styled-components';
import { minBreakpointQuery, brandColours } from '../../styles';

const StyledWrapper = styled.div`
  ${({ altTheme, isHomePage }) => {
    if (altTheme && isHomePage) {
      return css`
        padding-bottom: 40px;
        background-color: ${brandColours.slate[600]};

        ${minBreakpointQuery.small`
          padding-bottom: 60px;
        `}

        ${minBreakpointQuery.large`
          padding-bottom: 80px;
        `}

        ${minBreakpointQuery.xxxxlarge`
          padding-bottom: 100px;
        `}
      `;
    }
  }}
`;

export const Wrapper = ({ children, altTheme, isHomePage }) => (
  <StyledWrapper altTheme={altTheme} isHomePage={isHomePage}>
    {children}
  </StyledWrapper>
);
