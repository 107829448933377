import React from 'react';
import styled, { css } from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  sectionPaddings,
  standardColours,
  brandColours,
  brandFonts,
  fontSize,
  fluidFontSize,
} from '../styles';
import { Container, Overline, Button, Link, ArrowIcon } from './ui';

const StyledBanner = styled.section`
  ${sectionPaddings('20px', '60px')};
  text-align: center;

  ${({ altTheme }) => {
    if (altTheme) {
      return css`
        color: ${standardColours.white};
        background-color: ${brandColours.slate[600]};
      `;
    }
  }}
`;

const StyledInner = styled.div`
  ${({ isHomePage, isContactPage }) => {
    if (isHomePage || isContactPage) {
      return css`
        margin-bottom: 200px;
      `;
    }
  }}
`;

const StyledHeading = styled.h1`
  margin: auto;
  ${brandFonts.quartoBlack};
  ${fluidFontSize(
    '32px',
    '72px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};
  line-height: 1.2;

  strong {
    position: relative;
    display: inline-block;
    font-weight: inherit;

    &:before {
      content: '';
      position: absolute;
      right: -3px;
      bottom: -1px;
      left: -3px;
      z-index: -1;
      height: 15px;
      background-color: ${brandColours.clay[200]};

      ${minBreakpointQuery.small`
        height: 20px;
      `}

      ${minBreakpointQuery.medium`
        bottom: 0;
        right: -5px;
        left: -5px;
        height: 25px;
      `}

      ${minBreakpointQuery.large`
        height: 30px;
      `}
    }
  }
`;

const StyledText = styled.p`
  margin: 20px auto 0;
  max-width: 600px;
  ${fluidFontSize(
    '18px',
    '24px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};

  ${minBreakpointQuery.small`
    margin-top: 24px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 28px;
  `}
`;

const StyledButtonsWrapper = styled.div`
  margin-top: 15px;

  ${minBreakpointQuery.small`
    margin-top: 20px;
  `}

  ${minBreakpointQuery.medium`
    margin-top: 25px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 30px;
  `}
`;

const StyledButton = styled(Button)`
  margin: 8px;
`;

const StyledBackLink = styled(Link)`
  position: relative;
  margin-top: 20px;
  padding-left: 22px;
  ${fontSize(14)};

  ${minBreakpointQuery.small`
    margin-top: 30px;
  `}

  ${minBreakpointQuery.large`
    margin-top: 40px;
  `}
`;

const StyledIcon = styled(ArrowIcon)`
  position: absolute;
  top: 5px;
  left: 0;
  height: 10px;
  width: 14px;
  fill: ${brandColours.clay[300]};
  transform: rotate(180deg);
`;

const StyledContactFormButton = styled(Button)`
  margin-top: 20px;

  ${minBreakpointQuery.tiny`
    margin-top: 30px;
  `}

  ${minBreakpointQuery.medium`
    margin-top: 40px;
  `}

  ${minBreakpointQuery.xxlarge`
    margin-top: 50px;
  `}
`;

const Banner = ({
  overline,
  heading,
  text,
  links = [],
  backLink,
  altTheme,
  isHomePage,
  isContactPage,
}) => (
  <StyledBanner altTheme={altTheme}>
    <Container>
      <StyledInner isHomePage={isHomePage} isContactPage={isContactPage}>
        {overline && <Overline>{overline}</Overline>}
        <StyledHeading dangerouslySetInnerHTML={{ __html: heading }} />
        {text && <StyledText>{text}</StyledText>}
        {links.length > 0 && (
          <StyledButtonsWrapper>
            {links.map(({ id, text, page }, i) => (
              <StyledButton key={id} to={page.slug} linkData={page} alt={i % 2}>
                {text}
              </StyledButton>
            ))}
          </StyledButtonsWrapper>
        )}
        {backLink && (
          <StyledBackLink to={backLink.slug}>
            <StyledIcon />
            {backLink.text}
          </StyledBackLink>
        )}
        {isContactPage && (
          <StyledContactFormButton
            alt={altTheme}
            arrowDown={true}
            onClick={() =>
              document
                .getElementById('contact-info')
                .scrollIntoView({ behavior: 'smooth' })
            }
          >
            Jump to contact form
          </StyledContactFormButton>
        )}
      </StyledInner>
    </Container>
  </StyledBanner>
);

export default Banner;
