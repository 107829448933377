import React from 'react';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  standardColours,
  brandColours,
  fontSize,
  headingStyles,
  standardTransition,
} from '../../styles';

export const StyledHtmlContent = styled.div`
  ${({ altTheme }) => {
    if (altTheme) {
      return css`
        color: ${standardColours.white};
      `;
    }
  }}

  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 12px;

    ${minBreakpointQuery.small`
      margin-bottom: 14px;
    `}
  }

  h2 {
    ${headingStyles()};
  }

  h3 {
    ${fontSize(20)};

    ${minBreakpointQuery.small`
      ${fontSize(22)};
    `}
  }

  h4 {
    ${fontSize(19)};

    ${minBreakpointQuery.small`
      ${fontSize(20)};
    `}
  }

  h5 {
    ${fontSize(18)};

    ${minBreakpointQuery.small`
      ${fontSize(19)};
    `}
  }

  h6 {
    ${fontSize(17)};

    ${minBreakpointQuery.small`
      ${fontSize(18)};
    `}
  }

  p {
    margin-bottom: 20px;
    ${fontSize(16)};
    line-height: 1.7;

    ${minBreakpointQuery.small`
      margin-bottom: 24px;
      ${fontSize(18)};
    `}

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    position: relative;
    color: ${brandColours.clay[300]};
    border-bottom: 1px solid transparent;
    transition: ${standardTransition('border-color')};

    &:hover {
      border-bottom-color: ${brandColours.clay[300]};
    }
  }

  ul,
  ol {
    margin-bottom: 20px;

    ${minBreakpointQuery.small`
      margin-bottom: 24px;
    `}

    li {
      margin-bottom: 10px;
      ${fontSize(16)};
      line-height: 1.7;

      ${minBreakpointQuery.small`
        margin-bottom: 12px;
        ${fontSize(18)};
      `}

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 20px;

      &:before {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        display: inline-block;
        height: 8px;
        width: 8px;
        background-color: ${brandColours.clay[300]};
        border-radius: 50%;
      }
    }
  }

  ol {
    list-style-type: decimal;

    li {
      margin-left: 16px;
    }
  }
`;

export const HtmlContent = ({ html, altTheme, ...props }) => (
  <StyledHtmlContent
    dangerouslySetInnerHTML={{ __html: html }}
    altTheme={altTheme}
    {...props}
  />
);
