import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css } from 'styled-components';
import {
  minBreakpointQuery,
  maxBreakpointQuery,
  standardColours,
  brandColours,
  zIndexLayers,
  standardTransition,
} from '../styles';
import { Link, Svg } from './ui';
import logo from '../images/logo.svg';

const StyledLogo = styled(Link)`
  position: relative;
  display: inline-block;

  ${({ displayNav }) => {
    if (displayNav) {
      return css`
        ${maxBreakpointQuery.mlarge`
          z-index: ${zIndexLayers.fifth};
          background-color: ${brandColours.slate[500]};
        `}
      `;
    }
  }};
`;

const StyledSvg = styled(Svg)`
  height: 30px;
  width: 74px;
  transition: ${standardTransition('fill')};

  ${maxBreakpointQuery.mlarge`
    fill: ${({ displayNav, alt }) =>
      displayNav || alt ? standardColours.white : brandColours.slate[500]};
  `}

  ${minBreakpointQuery.tiny`
    height: 38px;
    width: 94px;
  `}

  ${minBreakpointQuery.small`
    height: 46px;
    width: 114px;
  `}

  ${minBreakpointQuery.mlarge`
    fill: ${({ alt }) =>
      alt ? standardColours.white : brandColours.slate[500]};
  `}

  ${minBreakpointQuery.large`
    height: 53px;
    width: 134px;
  `}
`;

const Logo = ({ displayNav, alt }) => {
  const {
    datoCmsSite: {
      globalSeo: { siteName },
    },
  } = useStaticQuery(graphql`
    query LogoQuery {
      datoCmsSite {
        globalSeo {
          siteName
        }
      }
    }
  `);

  return (
    <StyledLogo to="/" displayNav={displayNav}>
      <StyledSvg
        title={siteName}
        image={logo}
        displayNav={displayNav}
        alt={alt}
      />
    </StyledLogo>
  );
};

export default Logo;
