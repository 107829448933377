import { createGlobalStyle, css } from 'styled-components';
import { brandColours, brandFonts, fontSize } from '../styles';
import quartoBlackWoff2 from '../fonts/quarto-black.woff2';
import quartoBlackWoff from '../fonts/quarto-black.woff';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'QuartoBlack';
    src: url('${quartoBlackWoff2}') format('woff2'),
         url('${quartoBlackWoff}') format('woff');
    font-display: swap;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%;
    -webkit-font-smoothing: antialiased;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body {
    position: relative;
    margin: 0;
    padding: 0;
    color: ${brandColours.slate[500]};
    ${brandFonts.futuraPTBook};
    ${fontSize(16)}
    line-height: 1.3;
    background-color: ${({ altTheme }) =>
      altTheme ? brandColours.slate[600] : brandColours.clay[100]};

    ${({ scroll }) => {
      if (!scroll) {
        return css`
          overflow: hidden;
        `;
      }
    }};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0;
  }

  ul,
  ol {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
  
  img {
    height: auto;
    width: auto;
    vertical-align: middle;
    max-width: 100%;
    border: 0;
    -ms-interpolation-mode: bicubic;
  }
`;

export default GlobalStyle;
