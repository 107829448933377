import React from 'react';
import { graphql, Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';
import { buildUrl } from '../../utils';

const StyledLink = styled(GatsbyLink)`
  display: inline-block;
`;

export const Link = ({ to, linkData, children, ...props }) => (
  <StyledLink to={to === '/' ? to : buildUrl(to, linkData)} {...props}>
    {children}
  </StyledLink>
);

export const LinkFragment = graphql`
  fragment LinkFragment on Node {
    ... on DatoCmsHome {
      slug
    }

    ... on DatoCmsPage {
      slug
    }

    ... on DatoCmsCareersArchive {
      slug
    }

    ... on DatoCmsDepartmentsArchive {
      slug
    }

    ... on DatoCmsDepartment {
      slug
      internal {
        type
      }
    }

    ... on DatoCmsContact {
      slug
    }
  }
`;
