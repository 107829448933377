import React from 'react';
import styled from 'styled-components';
import { minBreakpointQuery } from '../../styles';

const StyledGridTwoColumnsRight = styled.div`
  display: grid;
  align-items: start;
  grid-gap: 25px;
  margin: 20px 0;

  ${minBreakpointQuery.small`
    grid-template-columns: 1.5fr 1fr;
    grid-gap: 30px;
  `}

  ${minBreakpointQuery.medium`
    grid-template-columns: 1.75fr 1fr;
    grid-gap: 35px;
  `}

  ${minBreakpointQuery.large`
    grid-template-columns: 2fr 1fr;
    grid-gap: 40px;
  `}
`;

const StyledGridMain = styled.div``;

const StyledGridSidebar = styled.aside`
  display: grid;
  grid-gap: 20px;

  ${minBreakpointQuery.large`
    grid-gap: 25px;
  `}
`;

export const GridTwoColumnsRight = ({ children, ...props }) => (
  <StyledGridTwoColumnsRight {...props}>{children}</StyledGridTwoColumnsRight>
);

export const GridMain = ({ children, ...props }) => (
  <StyledGridMain {...props}>{children}</StyledGridMain>
);

export const GridSidebar = ({ children, ...props }) => (
  <StyledGridSidebar {...props}>{children}</StyledGridSidebar>
);
