export const standardColours = {
  black: '#000',
  white: '#FFF',
  transparent: 'rgba(255, 255, 255, 0)',
};

export const brandColours = {
  slate: {
    100: '#F3F7FB',
    200: '#D4D4D4',
    300: '#606467',
    500: '#35363B',
    600: '#2B2C2E',
  },
  olive: {
    100: '#F1F3EB',
    200: '#D5D6D2',
    300: '#7C7E76',
    500: '#555751',
    600: '#3B3D39',
  },
  clay: {
    100: '#FCF7F1',
    200: '#ECD8CE',
    300: '#C88E7C',
    500: '#966151',
  },
  neutral: {
    100: '#EEEEEE',
    500: '#111111',
  },
};
