import React from 'react';
import styled from 'styled-components';
import {
  breakpointSizes,
  minBreakpointQuery,
  brandColours,
  fluidFontSize,
} from '../../styles';

const StyledOverline = styled.p`
  margin-bottom: 4px;
  color: ${brandColours.clay[500]};
  ${fluidFontSize(
    '18px',
    '24px',
    breakpointSizes.tiny,
    breakpointSizes.xxxxlarge
  )};

  ${minBreakpointQuery.small`
    margin-bottom: 8px;
  `}

  ${minBreakpointQuery.large`
    margin-bottom: 12px;
  `}
`;

export const Overline = ({ children, ...props }) => (
  <StyledOverline {...props}>{children}</StyledOverline>
);
